@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #121213;	
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .simple-keyboard.hg-layout-default .hg-button.hg-gray {
    @apply bg-stone-700 text-white
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-gray:active {
    @apply bg-stone-500 text-white
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-yellow {
    @apply bg-yellow-700 text-white
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-yellow:active {
    @apply bg-yellow-500 text-white
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-green {
    @apply bg-green-700 text-white
  }

  .simple-keyboard.hg-layout-default .hg-button.hg-green:active {
    @apply bg-green-500 text-white
  }

  .simple-keyboard.myTheme1 .hg-button {
    @apply bg-gray-600 text-white border-none
  }

  .simple-keyboard.myTheme1 .hg-button:active {
    @apply bg-gray-400 text-white border-none
  }
}

.simple-keyboard.myTheme1 {
  background-color: rgba(0, 0, 0, 0);

}

.hg-button.hg-functionBtn.hg-button-space {
  opacity: 0;
  cursor: default;
}

.ht-button.hg-functionBtn.hg-button-ent {
  text-transform: lowercase;
}

.ht-button.hg-functionBtn.hg-button-backspace {
  text-transform: lowercase;
}

.hg-theme-default .hg-button {
  border: none;
  height: 45px;
  text-transform: uppercase;
}